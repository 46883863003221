<template>
  <div>
    <div class="w1200" style="margin-bottom:50px">
      <div class="title">联系我们</div>
      <!-- 售前服务 -->
      <div class="before flex">
        <div class="lf flex">
          <div class="left">
            <img src="../../assets/images/contact/sqfw.png" alt="" srcset="" />
          </div>
          <div class="right">
            <div class="title1 f24" style="margin-bottom:10px">售前服务</div>
            <p class="f16">需要升级专业版或企业版用户&其他<br/>软硬件购买</p>
          </div>
        </div>
        <div class="rt">
            <div class="flex align-center">
                <img src="../../assets/images/contact/phone.png" alt="" srcset="">
                <p>400-066-0526</p>
            </div>
            <div class="flex align-center">
                <img src="../../assets/images/contact/qq.png" alt="" srcset="">
                <p>liyongsheng@yunlankeji.com</p>
            </div>
            <div class="flex align-center">
                <img src="../../assets/images/contact/address.png" alt="" srcset="">
                <p>安徽省合肥市蜀山区西湖国际广场B座1504-1509室</p>
            </div>
        </div>
      </div>
      <!-- 售后服务 -->
      <div class="before flex">
        <div class="lf flex">
          <div class="left">
            <img src="../../assets/images/contact/shfw.png" alt="" srcset="" />
          </div>
          <div class="right">
            <div class="title1 f24" style="margin-bottom:10px">售后服务</div>
            <p class="f16">遇到账户问题或店教授收阴产品及服<br/>物问题</p>
          </div>
        </div>
        <div class="rt">
            <div class="flex align-center">
                <img src="../../assets/images/contact/phone.png" alt="" srcset="">
                <p>400-066-0526</p>
            </div>
            <div class="flex align-center">
                <img src="../../assets/images/contact/qq.png" alt="" srcset="">
                <p>liyongsheng@yunlankeji.com</p>
            </div>
            <div class="flex align-center">
                <img src="../../assets/images/contact/address.png" alt="" srcset="">
                <p>安徽省合肥市蜀山区西湖国际广场B座1504-1509室</p>
            </div>
        </div>
      </div>
      <!-- 渠道合作 -->
      <div class="before flex">
        <div class="lf flex">
          <div class="left">
            <img src="../../assets/images/contact/qdhz.png" alt="" srcset="" />
          </div>
          <div class="right">
            <div class="title1 f24" style="margin-bottom:10px">渠道合作</div>
            <p class="f16">想要成为店教授收银区域代理商或者<br/>核心代理商</p>
          </div>
        </div>
        <div class="rt">
            <div class="flex align-center">
                <img src="../../assets/images/contact/phone.png" alt="" srcset="">
                <p>400-066-0526</p>
            </div>
            <div class="flex align-center">
                <img src="../../assets/images/contact/qq.png" alt="" srcset="">
                <p>liyongsheng@yunlankeji.com</p>
            </div>
            <div class="flex align-center">
                <img src="../../assets/images/contact/email.png" alt="" srcset="">
                <p>liyongsheng@yunlankeji.com</p>
            </div>
            <div class="flex align-center">
                <img src="../../assets/images/contact/address.png" alt="" srcset="">
                <p>安徽省合肥市蜀山区西湖国际广场B座1504-1509室</p>
            </div>
        </div>
      </div>
      <!-- 市场合作 -->
      <div class="before flex">
        <div class="lf flex">
          <div class="left">
            <img src="../../assets/images/contact/qdhz.png" alt="" srcset="" />
          </div>
          <div class="right">
            <div class="title1 f24" style="margin-bottom:10px">市场合作</div>
            <p class="f16">关于广告投放或媒体采访的详细安排<br/>及策划</p>
          </div>
        </div>
        <div class="rt">
            <div class="flex align-center">
                <img src="../../assets/images/contact/phone.png" alt="" srcset="">
                <p>400-066-0526</p>
            </div>
            <div class="flex align-center">
                <img src="../../assets/images/contact/qq.png" alt="" srcset="">
                <p>liyongsheng@yunlankeji.com</p>
            </div>
            <div class="flex align-center">
                <img src="../../assets/images/contact/email.png" alt="" srcset="">
                <p>liyongsheng@yunlankeji.com</p>
            </div>
            <div class="flex align-center">
                <img src="../../assets/images/contact/address.png" alt="" srcset="">
                <p>安徽省合肥市蜀山区西湖国际广场B座1504-1509室</p>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.title {
  font-size: 40px;
  margin: 40px 0 20px 0;
  color: #ff6727;
}
.before{
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
}
.lf{
    width: 50%;
    padding: 20px 50px;
    box-sizing: border-box;
    border-right: 1px solid #333;
}
.rt{
    width: 50%;
    padding: 20px 0;
    padding-left: 100px;
    color: #999;
}
.lf .left{
    margin-right: 40px;
}
.lf .right p{
    color: #999;
    line-height: 20px;
}
.rt img{
    margin-right: 20px;
}
.rt div{
    margin-bottom: 10px;
}
</style>